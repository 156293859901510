<template>
  <div>
    <b-modal
      id="return-back-hold"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ReturnBack')"
      hide-footer
      size="md"
      @close="onCancel"
    >
      <div style="display: flex;flex-direction: column;gap: 10px">
        <b-form-group
          :label="$t('Team')"
        >
          <vue-select
            v-model="$v.newTeamNameId.$model"
            :options="getDropdownTeamName"
            label="teamName"
            :reduce="(e) => e.teamNameId"
            :state="validateState('newTeamNameId')"
            :placeholder="$t('Select')"
          />
          <p
            v-if="!newTeamNameId && buttonSubmit"
            class="invalid"
          >
            {{ $t('This_is_a_required_field') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('State')"
        >
          <vue-select
            v-model="$v.newState.$model"
            :options="filteredOrderItemStates"
            :placeholder="$t('Select')"
            :state="validateState('newState')"
            label="state"
          />
          <p
            v-if="!newState && buttonSubmit"
            class="invalid"
          >
            {{ $t('This_is_a_required_field') }}
          </p>
        </b-form-group>
        <b-form-group
          :label="$t('Description')"
        >
          <b-form-textarea
            v-model="$v.notes.$model"
            :state="validateState('notes')"
            :placeholder="$t('Write..')"
          />
          <p
            v-if="!notes && buttonSubmit"
            class="invalid"
          >
            {{ $t('This_is_a_required_field') }}
          </p>
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';



export default {
  components: {
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['orderItemIdState', 'orderNumberState'],
  data() {
    return {
      newTeamNameId: null,
      newState: null,
      notes: null,
      buttonSubmit: false,
    };
  },
  validations: {
    newTeamNameId: {
      required,
    },
    newState: {
      required,
    },
    notes: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getDropdownTeamName', 'getOrderItemStates']),
    filteredOrderItemStates() {
      return this.getOrderItemStates.filter((state) => { return state !== 'QualityCheckNotPassed' && state !== 'OnHold' && state !== 'StockNotAvailable' });
    },
  },
  watch: {

  },
  methods: {
    ...mapActions([]),
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.buttonSubmit = true
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const objekti = {
        orderItemId: this.orderItemIdState,
        newTeamNameId: this.newTeamNameId,
        newState: this.newState,
        notes: this.notes,
      }
      this.$emit('returnBackHold', objekti)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.newTeamNameId = null
      this.newState = null
      this.notes = null
      this.buttonSubmit = false
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-form-invalid-color);
}

</style>
